//8个人中心-17钥匙管理-3其他人
<template>
  <div class="rate_page">
    <!--rate_page_head start-->
    <div class="rate_page_head flex flex-between">
      <div class="rate_nav flex">
        <div class="li" @click="changeType(item.type)" :class="{active:item.type == from.type}" v-for="(item,index) in headList" :key="index">{{ item.name }}</div>
      </div>
    </div>
    <!--rate_page_head end-->
    <!--rate_page_list start-->
    <div class="rate_page_list">
      <div class="key_page_select">
        <div class="select_item">
          <div class="label">区域:</div>
          <div class="select">
            <el-cascader
              clearable
              size="medium"
              :options="regionList"
              v-model="region"
              placeholder="请选择"
              @change="handleChangeCity"
            >
            </el-cascader>
          </div>
        </div>
        <div class="select_item">
          <div class="label">门店:</div>
          <div class="select">
            <el-select clearable @change="changeData" v-model="from.dian_id" placeholder="请选择">
              <el-option
                v-for="item in shopList"
                :key="item.id"
                :label="item.dian_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- <div class="select_item">
          <div class="label">经理:</div>
          <div class="select">
            <el-select v-model="from.uuid" placeholder="请选择" size="160px">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div> -->
      </div>
      <ul class="personage_ul">
        <li v-for="(item) in list" :key="item.id">
          <div class="personage_ul_img m-r-20">
            <img
              :src="item.fang.fengmian_image"
              alt=""
            />
            <div class="personage_img_marking">{{ item.fang.fang_level }}</div>
          </div>
          <div class="personage_ul_main f-1">
            <div class="flex flex-between">
              <div class="personage_ul_name">{{ item.fang.name }}</div>
              <div class="name_status" v-if="item.status == 0">未还</div>
            </div>
            <div class="personage_ul_title">
              {{ item.fang.fang_xing }} | {{ item.fang.fang_mianji }}m² | 8次 | 2天 | 王珊珊
            </div>
            <div class="personage_ul_tags">
              <span class="tag" v-for="(item) in item.fang.fang_biaoqian" :key="item">{{ item }}</span>
            </div>
            <div class="personage_ul_price flex-between">
              <div class="flex">
                <div class="personage_ul_price_left m-r-20">
                  <span>{{ item.fang.zong_price || 0 }}万</span>
                  <img src="../../assets/image/personage/up2.png" alt="" />
                </div>
                <div class="personage_ul_price_right">
                  <span>{{ item.fang.can_price }}元/m²</span>
                  <img src="../../assets/image/personage/up1.png" alt="" />
                </div>
              </div>

              <div class="personage_ul-bot">
                <button
                  class="rate_page_btn m-r-20"
                  @click="goRecord(item)"
                >借还记录</button>
                <button v-if="item.status == 0" class="rate_page_btn blue" @click="$refs.return.open(item.fang_id,item.fang_type,item.id)">还钥匙</button>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="paging" v-if="list.length">
        <el-pagination
          background
          layout="prev, pager, next, jumper, ->, total"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <!--rate_page_list end-->
    <!-- 借钥匙弹框 S -->
    <borrow-pop ref="borrow"></borrow-pop>
    <!-- 借钥匙弹框 E -->
    <!-- 还钥匙弹框 S -->
    <return-pop ref="return" @succeed="changeData"></return-pop>
    <!-- 还钥匙弹框 E -->
    <!-- 借还记录弹框 S -->
    <record-pop ref="record"></record-pop>
    <!-- 借还记录弹框 E -->
    <!-- 借还记录未还清弹框 S -->
    <record-popn ref="recordn"></record-popn>
    <!-- 借还记录未还清弹框 E -->
  </div>
</template>

<script>
import BorrowPop from "../../components/BorrowPop";
import ReturnPop from "../../components/ReturnPop";
import RecordPop from "../../components/RecordPop";
import RecordPopn from "../../components/RecordPopn";
// import 'swiper/css/swiper.css'
import { regionData, CodeToText } from "element-china-area-data";
export default {
  components: { BorrowPop, ReturnPop, RecordPop, RecordPopn },
  data() {
    return {
      regionList: regionData,
      CodeToText: CodeToText,
      region: [], //区域
      total: 0, //条数
      list: [], //列表
      from:{
        type:10,//10=全部0=未还1=已还
        city:'',//区域搜索 省/市/区
        dian_id:'',//门店搜索 门店id
        uuid:'',//人员搜索 人员id,
        page:1
      },
      headList:[
        {
          name:'全部',
          type:10
        },
        {
          name:'钥匙缺失',
          type:0
        },
        {
          name:'钥匙完整',
          type:1
        }
      ],
      options:[],
      shopList:[],//门店列表
    };
  },
  methods:{
     //切换头部
    changeType (type) {
      this.from.type = type
      this.init()
    },
    changeData () {
      this.init()
    },
    init () {
      this.total = 0
      this.from.page = 1
      this.list = []
      this.getList()
    },
    //去接还记录
    goRecord (item) {
      this.$router.push({path:'/personage/keyRecord',query:{id:item.fang_id,fang_type:item.fang_type}})
    },
    //获取记录
    getList () {
      this.$axios.keyJieLists(this.from).then(res=>{
        this.list = res.data.data
        this.total = res.data.total
      })
    },
    //获取门店列表
    getShopList () {
      this.$axios.menDian().then(res=>{
        this.shopList = res.data
      })
    },
    //选择区域
    handleChangeCity() {
      let data = [];
      for (let i = 0; i < this.region.length; i++) {
        data.push(this.CodeToText[this.region[i]]);
      }
      this.from.city = data.join("/");
      this.init()
    },
  },
  created () {
    this.getShopList()
    this.getList()
  }
};
</script>

<style lang="less" scoped>
.key_page_select {
  display: flex;
  align-items: center;
  padding-top: 20px;
  .select_item {
    display: flex;
    font-size: 14px;
    color: #666;
    align-items: center;
    width: 33.333%;
  }
  .label {
    margin-right: 10px;
    white-space: nowrap;
    text-align: right;
  }
  .select {
    width: 160px;
  }
  /deep/ .el-input__inner {
    border: 1px solid #ccc;
    border-radius: 0;
    height: 36px;
    line-height: 36px;
  }
}
/deep/.key_page_select .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 200px;
}
.rate_page_head {
  background: #fff;
  padding: 0 40px;
  align-items: center;
  margin-bottom: 10px;
  height: 64px;
  .rate_nav {
    .li {
      font-size: 18px;
      color: #666;
      margin-right: 55px;
      cursor: pointer;
      position: relative;
      line-height: 64px;
    }
    .active {
      color: #3273f6;
    }
    .active:after {
      width: 24px;
      height: 2px;
      background: #3273f6;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -12px;
      display: block;
      content: "";
    }
  }
  .rate_total {
    font-size: 18px;
  }
}
.rate_page_list {
  background: #fff;
  padding: 0 50px;
}
.personage_ul_tags {
  display: flex;
  margin: 0 -5px;
  .tag {
    background: #f5f5f5;
    height: 28px;
    border-radius: 3px;
    padding: 0 20px;
    margin: 5px;
    font-size: 14px;
    color: #999;
    line-height: 28px;
  }
}
.rate_page_btn {
  width: 112px;
  height: 40px;
  border: 1px solid #3273f6;
  border-radius: 4px;
  color: #3273f6;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  background: #fff;
  outline: none;
  cursor: pointer;
}
.rate_page_btn.orange {
  background: #ffa740;
  border: 1px solid #ffa740;
  color: #fff;
}
.rate_page_btn.blue {
  background: #3273f6;
  border: 1px solid #3273f6;
  color: #fff;
}
.personage_ul {
  .name_status {
    font-size: 16px;
    color: #ffa740;
  }
  li {
    padding: 40px 0;
    display: flex;
    border-bottom: 1px solid #ebebeb;
    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }
      position: relative;
      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }
    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
      }
      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }
      .personage_ul_price {
        display: flex;
        align-items: flex-end;
        .personage_ul_price_left {
          display: flex;
          align-items: center;
          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }
        .personage_ul_price_right {
          display: flex;
          align-items: center;
          span {
            color: #999999;
            font-size: 18px;
          }
        }
        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }
      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;
        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }
        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          margin-right: 10px;
        }
        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }
        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;
            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
